








































import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action } from "vuex-class";
import { decimalToTokenBaseUnits } from "@/utils/tokens";
import { AssetSymbol, Contracts } from "@/utils/types";
import { ContractTransaction, ethers } from "ethers";
import { ILeveragePool } from "@/contracts/ILeveragePool";
import ILeveragePoolABI from "@/contracts/ILeveragePool.json";
import { ActionMethod } from "vuex";
import AccountButton from "@/components/AccountButton.vue";

// TODO: Disable functionality if not connected!

enum WithdrawlStatus {
  PENDING,
  CONFIRMED
}

type Withdrawl = {
  amount: number;
  tx: ContractTransaction;
  status: WithdrawlStatus;
};

@Component({
  components: { AccountButton }
})
export default class extends Vue {
  @Prop({ required: true }) readonly asset!: AssetSymbol;
  @Prop({ required: true }) readonly id!: string;

  @State provider!: ethers.providers.Web3Provider;
  @State signer!: ethers.Signer;
  @State address!: string;
  @State contracts!: Contracts;

  @Getter isConnected!: boolean;

  @Action registerConnectionCallback!: ActionMethod;
  @State depositedBalances!: { [key: string]: number };

  private formValues: any = {};

  private withdrawls: Withdrawl[] = [];
  $toasted: any;
  $modal: any;

  get pendingWithdrawls(): Withdrawl[] {
    return this.withdrawls.filter(
      withdrawl => withdrawl.tx.confirmations === 0
    );
  }

  get totalPendingAmount(): number {
    return this.withdrawls.reduce((a, b) => a + b.amount, 0);
  }

  get amountAvailable(): number {
    if (!this.isConnected) {
      return 0;
    } else {
      return this.depositedBalances[this.asset] ?? 0;
    }
  }

  private async withdraw() {
    const amount = this.formValues.withdrawAmount;

    const baseUnitAmount = decimalToTokenBaseUnits(this.asset, amount);

    const reserves = await this.leveragePool.getReserveForToken(
      this.contracts[this.asset]
    );

    if (reserves.length === 0) {
      console.warn("No reserves for asset", this.asset);
      return;
    }

    if (reserves.length > 1) {
      console.warn("More than 1 reserve for asset", this.asset);
    }

    const overlyingTokenAddress = reserves[0];

    const tx = await this.leveragePool.withdraw(
      this.address,
      this.address,
      overlyingTokenAddress,
      baseUnitAmount
    );

    const depositIndex = this.withdrawls.length;
    Vue.set(this.withdrawls, depositIndex, {
      tx,
      amount: parseFloat(amount),
      status: WithdrawlStatus.PENDING
    });

    this.$modal.hide(this.id);

    const toast = this.$toasted.info(
      "Deposit transaction submitted! Awaiting confirmation...",
      {
        action: {
          text: "Dismiss",
          onClick: (e: any, toastObject: any) => {
            toastObject.goAway(0);
          }
        }
      }
    );

    // TODO: This should be synced up with a backend server (or maybe local storage is enough?)
    // so that users can get this notification even when the go offline.
    await tx.wait(1 /* confirmations */);
    toast.goAway(0);
    // Tx has been mined
    Vue.set(this.withdrawls[depositIndex], "status", WithdrawlStatus.CONFIRMED);
    this.$toasted.success(
      `Transaction confirmed! Your withdrawl of ${amount} ${this.asset} has succeeded!`,
      {
        action: {
          text: "Dismiss",
          onClick: (e: any, toastObject: any) => {
            toastObject.goAway(0);
          }
        }
      }
    );
  }

  get leveragePool() {
    const leveragePoolAddress = this.contracts.LeveragePool;
    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      this.signer
    ) as ILeveragePool;

    return leveragePool;
  }
}
