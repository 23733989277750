


































import { Component, Prop, Vue } from 'vue-property-decorator';
import type { ActionMethod } from 'vuex';
import { State, Action, Getter } from 'vuex-class';
import FlatButton from '@/components/FlatButton.vue';
import DepositModal from '@/components/lending/DepositModal.vue';
import WithdrawModal from '@/components/lending/WithdrawModal.vue';
import { BigNumberish, ethers } from 'ethers';
import { ILeveragePool } from "@/contracts/ILeveragePool";
import ILeveragePoolABI from "@/contracts/ILeveragePool.json";
import { OverlyingToken } from "@/contracts/OverlyingToken";
import OverlyingTokenABI from "@/contracts/OverlyingToken.json";
import {AssetSymbol, Contracts} from "@/utils/types";
import { baseTokenUnitsToDecimal } from '@/utils/tokens';
import { AsyncComputed } from '@/utils/AsyncComputed';
import { tokenDecimals } from '@/utils/constants';
import DebtTokenABI from "@/contracts/DebtToken.json";
import { formatBigNumberString, formatRayPercentage } from "@/utils/helpers";
import IInterestModelABI from "@/contracts/IInterestModel.json";
import { IInterestModel } from "@/contracts/IInterestModel";
import { IDebtToken } from '@/contracts/IDebtToken';

@Component({
  components: { FlatButton, DepositModal, WithdrawModal }
})
export default class extends Vue {
  @Prop({ required: true }) readonly asset!: AssetSymbol;
  @Prop({ required: true }) readonly index!: number;

  @State connectionStatus!: boolean;
  @State provider!: ethers.providers.Provider;
  @State address!: string;
  @State contracts!: Contracts;
  @State depositedBalances!: { [key: string]: string };

  @Getter isConnected!: boolean;

  @Action registerConnectionCallback!: ActionMethod;

  get computedStyle() {
    return {
      background: this.index % 2 == 0 ? "var(--bg-color)" : "var(--bg-color-alt)"
    }
  }

  @AsyncComputed()
  async rate() {
    const leveragePoolAddress = this.contracts.LeveragePool;

    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      this.provider,
    ) as ILeveragePool;

    const reserveAddress = (await leveragePool.getReserveForToken(this.contracts[this.asset]))[0];
    const reserve = await leveragePool.getReserveData(reserveAddress);

    const totalDebt = reserve.cached.totalDebt;
    const totalSaving = reserve.cached.totalSaving;
    const interestRateModelAddress = reserve.interestModel;

    const interestRateModel = new ethers.Contract(
      interestRateModelAddress,
      IInterestModelABI,
      this.provider
    ) as IInterestModel;

    const underlyingAssetAddress = this.contracts[this.asset];
    const [liquidityRate, ] = await interestRateModel.getInterestRates(underlyingAssetAddress, totalDebt, totalSaving);

    return formatRayPercentage(liquidityRate, 2);
  }

  @AsyncComputed({ watch: ['rate'] })
  async rollingRate() {
    // TEMP:
    return this.rate;

    // return "-";
  }

  get depositPopupId(): string {
    return `deposit-${this.asset}`;
  }

  get withdrawPopupId(): string {
    return `withdraw-${this.asset}`;
  }

  get formattedSuppliedAmount() {
    if (!this.isConnected) {
      return '-';
    }

    const balance = this.depositedBalances[this.asset];
    if (balance === undefined) {
      console.error("Missing asset!");
      return '-';
    }

    return formatBigNumberString(balance);
  }

  async overlyingToken() {
    const leveragePoolAddress = this.contracts.LeveragePool;

    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      this.provider,
    ) as ILeveragePool;

    const tokenAddress = this.contracts[this.asset];
    const reserves = await leveragePool.getReserveForToken(tokenAddress);

    if (reserves.length > 1) {
      console.warn("More than 1 reserve for asset", this.asset);
    }

    const overlyingToken = new ethers.Contract(
      reserves[0],
      OverlyingTokenABI,
      this.provider,
    ) as OverlyingToken;

    return overlyingToken;
  }

  async debtToken() {
    const leveragePoolAddress = this.contracts.LeveragePool;

    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      this.provider,
    ) as ILeveragePool;

    const tokenAddress = this.contracts[this.asset];
    const reserves = await leveragePool.getReserveForToken(tokenAddress);

    if (reserves.length > 1) {
      console.warn("More than 1 reserve for asset", this.asset);
    }

    const reserveData = await leveragePool.getReserveData(reserves[0]);

    const debtToken = new ethers.Contract(
      reserveData.debtToken,
      DebtTokenABI,
      this.provider,
    ) as IDebtToken;

    return debtToken;
  }

  async getTVL(): Promise<string> {
    const overlyingToken = await this.overlyingToken();

    const baseUnitBalance = await overlyingToken.totalSupply();
    const decimalBalance = baseTokenUnitsToDecimal(this.asset, baseUnitBalance);

    return decimalBalance;
  }

  @AsyncComputed()
  async formattedTVLInUSD() {
    const tvl = await this.getTVL();

    // TODO: Get exchange rate
    const exchangeRate = 1;
    const baseUnitTvlInUsd = ethers.utils.parseUnits(tvl, tokenDecimals[this.asset]).mul(exchangeRate);
    const tvlInUsd = ethers.utils.formatUnits(baseUnitTvlInUsd, tokenDecimals[this.asset]);

    return formatBigNumberString(tvlInUsd);
  }

  @AsyncComputed()
  async formattedTVL() {
    const tvl = await this.getTVL();

    return formatBigNumberString(tvl);
  }

  @AsyncComputed()
  async formattedEarnedAmount() {
    if (!this.isConnected) {
      return '-';
    }

    const overlyingToken = await this.overlyingToken();
    const abi = [ "event InternalUnderlyingTransfer(address indexed from, address indexed to, uint256 amount)" ];
    const iface = new ethers.utils.Interface(abi);

     // List all token transfers *to* address
    const toFilter = {
      address: overlyingToken.address,
      fromBlock: 0,
      topics: [
        // the name of the event, parentheses containing the data type of each event, no spaces
        ethers.utils.id("InternalUnderlyingTransfer(address,address,uint256)"),
        null,
        ethers.utils.hexZeroPad(this.address, 32)
      ]
    };

    const toEvents = await overlyingToken.queryFilter(toFilter);

    let provided = ethers.BigNumber.from(0);
    for (const log of toEvents) {
      const event = iface.parseLog(log);
      provided = provided.add(event.args.amount);
    }

    if (provided.eq(0)) {
      return "-";
    }

    // List all token transfers *from* address
    const fromFilter = {
      address: overlyingToken.address,
      fromBlock: 0,
      topics: [
        // the name of the event, parentheses containing the data type of each event, no spaces
        ethers.utils.id("InternalUnderlyingTransfer(address,address,uint256)"),
        ethers.utils.hexZeroPad(this.address, 32)
      ]
    };

    const fromEvents = await overlyingToken.queryFilter(fromFilter);

    let withdrawn = ethers.BigNumber.from(0);
    for (const log of fromEvents) {
      const event = iface.parseLog(log);
      withdrawn = withdrawn.add(event.args.amount);
    }

    const currentBalance = await overlyingToken.balanceOf(this.address);
    const earned = currentBalance.sub(provided.sub(withdrawn));

    // TODO
    return formatBigNumberString(ethers.utils.formatUnits(earned, tokenDecimals[this.asset]));
  }

  async getDebtAmount() {
    const debtToken = await this.debtToken();

    const baseUnitBalance = await debtToken.totalSupply();
    const decimalBalance = baseTokenUnitsToDecimal(this.asset, baseUnitBalance);

    return decimalBalance;
  }

  @AsyncComputed()
  async formattedUtilization() {
    const overlyingToken = await this.overlyingToken();
    const tvl = await overlyingToken.totalSupply();

    if (tvl.eq(0)) {
      return "-";
    }

    const debtToken = await this.debtToken();
    const debtAmount = await debtToken.totalSupply();

    const precision = 4;
    const precisionMultipler = ethers.BigNumber.from(10).pow(precision);
    const utilization = debtAmount.mul(precisionMultipler).mul(100).div(tvl);

    const [integer, decimals] = ethers.utils.formatUnits(utilization, precision).split('.');

    return `${integer}.${(decimals?.charAt(0) ?? '0') + (decimals?.charAt(1) ?? '0')}`;
  }

  // private async fetchSuppliedAmount() {
  //   const leveragePoolAddress = this.contracts.LeveragePool;

  //   const leveragePool = new ethers.Contract(
  //     leveragePoolAddress,
  //     ILeveragePoolABI,
  //     this.provider,
  //   ) as ILeveragePool;

  //   const tokenAddress = this.contracts[this.asset];
  //   const reserves = await leveragePool.getReserveForToken(tokenAddress);

  //   if (reserves.length === 0) {
  //     console.warn("No reserves for asset", this.asset);
  //     return;
  //   }

  //   if (reserves.length > 1) {
  //     console.warn("More than 1 reserve for asset", this.asset);
  //   }

  //   const overlyingToken = new ethers.Contract(
  //     reserves[0],
  //     OverlyingTokenABI,
  //     this.provider,
  //   ) as OverlyingToken;

  //   const baseUnitBalance = await overlyingToken.balanceOf(this.address);
  //   const decimalBalance = baseTokenUnitsToDecimal(this.asset, baseUnitBalance);
  //   this.suppliedAmount = decimalBalance;
  // }
}
