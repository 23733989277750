












import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/PageHeader.vue";
import BalanceSummary from "@/components/lending/BalanceSummary.vue";
import LendingMarketList from "@/components/lending/LendingMarketList.vue";
import { reserveUnderlyingAssets } from "@/config/assets";

@Component({
  components: { PageHeader, BalanceSummary, LendingMarketList }
})
export default class Lend extends Vue {
  private assets = reserveUnderlyingAssets;
}
