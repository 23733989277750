
















import { Component, Prop, Vue } from 'vue-property-decorator';
import type { ActionMethod } from 'vuex';
import { State, Getter, Action } from 'vuex-class';
import LendingMarketEntry from "@/components/lending/LendingMarketEntry.vue";
import { AssetSymbol } from '@/utils/types';
import { BigNumberish } from '@ethersproject/bignumber';

@Component({
  components: { LendingMarketEntry }
})
export default class extends Vue {
  @Prop({ required: true }) readonly assets!: string[];

  @State connectionStatus!: boolean;

  @Action connect!: ActionMethod;
}
