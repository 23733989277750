



































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation } from "vuex-class";
import {
  baseTokenUnitsToDecimal,
  decimalToTokenBaseUnits,
  safeApprove
} from "@/utils/tokens";
import { AssetMapping, AssetSymbol, Contracts } from "@/utils/types";
import { ContractTransaction, ethers, Transaction } from "ethers";
import IERC20ABI from "@/contracts/IERC20.json";
import { IERC20 } from "@/contracts/IERC20";
import OverlyingTokenABI from "@/contracts/OverlyingToken.json";
import { OverlyingToken } from "@/contracts/OverlyingToken";
import { ILeveragePool } from "@/contracts/ILeveragePool";
import ILeveragePoolABI from "@/contracts/ILeveragePool.json";
import AccountButton from "@/components/AccountButton.vue";
import { ActionMethod, MutationMethod } from "vuex";
import { AsyncComputed } from "@/utils/AsyncComputed";
import FlatButton from "@/components/FlatButton.vue";

// TODO: Disable functionality if not connected!

enum DepositStatus {
  PENDING,
  CONFIRMED
}

type Deposit = {
  amount: number;
  tx: ContractTransaction;
  status: DepositStatus;
};

@Component({
  components: { AccountButton, FlatButton }
})
export default class extends Vue {
  @Prop({ required: true }) readonly asset!: AssetSymbol;
  @Prop({ required: true }) readonly id!: string;

  @State provider!: ethers.providers.Web3Provider;
  @State signer!: ethers.Signer;
  @State address!: string;
  @State contracts!: Contracts;
  @State tokenBalances!: { [key: string]: number };
  @State pendingAllowanceUpdateTxs!: AssetMapping<Transaction[]>;
  @State allowances!: AssetMapping<string>;

  @Getter isConnected!: boolean;

  @Action registerConnectionCallback!: ActionMethod;

  @Mutation setPendingAllowanceUpdateTxs!: MutationMethod;

  private modifyApprovedAmount: boolean = false;

  private allowanceForm: any = {};
  private formValues: any = {};

  private deposits: Deposit[] = [];
  $modal: any;
  $toasted: any;

  get pendingDeposits(): Deposit[] {
    return this.deposits.filter(deposit => deposit.tx.confirmations === 0);
  }

  get totalPendingAmount(): number {
    return this.deposits.reduce((a, b) => a + b.amount, 0);
  }

  get amountAvailable(): number {
    if (!this.isConnected) {
      return 0;
    } else {
      return this.tokenBalances[this.asset] ?? 0;
    }
  }

  get allowance() {
    if (!this.isConnected) {
      return "0";
    }

    return this.allowances[this.asset];
  }

  allowanceGreaterThanOrEqual(value: string): boolean {
    return decimalToTokenBaseUnits(this.asset, this.allowance).gte(
      decimalToTokenBaseUnits(this.asset, value)
    );
  }

  @AsyncComputed()
  async hasPendingAllowanceUpdateTx() {
    return this.pendingAllowanceUpdateTxs[this.asset]?.length > 0;
  }

  openPendingTx() {
    // TODO: Implement
    window.open("https://etherscan.io/", "_blank");
  }

  private async approve() {
    const pendingAllowanceUpdateTxs = this.pendingAllowanceUpdateTxs;

    const tokenAddress = this.contracts[this.asset];
    const tokenContract = new ethers.Contract(
      tokenAddress,
      IERC20ABI,
      this.signer
    ) as IERC20;

    const leveragePoolAddress = this.contracts.LeveragePool;
    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      this.signer
    ) as ILeveragePool;

    const reserves = await leveragePool.getReserveForToken(
      this.contracts[this.asset]
    );

    if (reserves.length === 0) {
      console.warn("No reserves for asset", this.asset);
      return;
    }

    if (reserves.length > 1) {
      console.warn("More than 1 reserve for asset", this.asset);
    }

    const overlyingTokenAddress = reserves[0];

    const amount = this.allowanceForm.approveAmount;
    const baseUnitAmount = decimalToTokenBaseUnits(this.asset, amount);
    const tx = await tokenContract.approve(
      overlyingTokenAddress,
      baseUnitAmount
    );

    let txs: Transaction[] = pendingAllowanceUpdateTxs[this.asset] ?? [];
    txs.push(tx);

    window.localStorage.setItem(
      "pendingAllowanceUpdateTxs",
      JSON.stringify(pendingAllowanceUpdateTxs)
    );

    this.setPendingAllowanceUpdateTxs({ asset: this.asset, txs });

    this.modifyApprovedAmount = false;

    const txReceipt = await tx.wait(1);
  }

  private async supply() {
    const amount = this.formValues.depositAmount;

    const baseUnitAmount = decimalToTokenBaseUnits(this.asset, amount);

    const tokenAddress = this.contracts[this.asset];

    // const tokenContract = new ethers.Contract(
    //   tokenAddress,
    //   IERC20ABI,
    //   this.signer
    // ) as IERC20;

    const leveragePoolAddress = this.contracts.LeveragePool;
    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      this.signer
    ) as ILeveragePool;

    const reserves = await leveragePool.getReserveForToken(
      this.contracts[this.asset]
    );

    if (reserves.length === 0) {
      console.warn("No reserves for asset", this.asset);
      return;
    }

    if (reserves.length > 1) {
      console.warn("More than 1 reserve for asset", this.asset);
    }

    const overlyingTokenAddress = reserves[0];

    // // TODO: Allow users to approve for more so they don't have to do this everytime
    // await safeApprove(
    //   tokenContract,
    //   this.signer,
    //   overlyingTokenAddress,
    //   baseUnitAmount
    // );

    const tx = await leveragePool.deposit(
      this.address /* on behalf of */,
      overlyingTokenAddress,
      baseUnitAmount
    );

    this.$modal.hide(this.id);

    const depositIndex = this.deposits.length;
    Vue.set(this.deposits, depositIndex, {
      tx,
      amount: parseFloat(amount),
      status: DepositStatus.PENDING
    });

    const toast = this.$toasted.info(
      "Deposit transaction submitted! Awaiting confirmation",
      {
        action: {
          text: "Dismiss",
          onClick: (e: any, toastObject: any) => {
            toastObject.goAway(0);
          }
        }
      }
    );

    // TODO: This should be synced up with a backend server (or maybe local storage is enough?)
    // so that users can get this notification even when the go offline.
    await tx.wait(1 /* confirmations */);
    toast.goAway(0);
    // Tx has been mined
    Vue.set(this.deposits[depositIndex], "status", DepositStatus.CONFIRMED);
    this.$toasted.success(
      `Transaction confirmed! Your deposit of ${amount} ${this.asset} has succeeded!`,
      {
        action: {
          text: "Dismiss",
          onClick: (e: any, toastObject: any) => {
            toastObject.goAway(0);
          }
        }
      }
    );

    // Await for more confirmations
    // TODO: Figure out if and how many confirmatoins we want to wait for
    // const confirmations = 5;
    // await tx.wait(5);
  }

  close() {
    this.$modal.hide(this.id);
    this.modifyApprovedAmount = false;
  }
}
