


























import { allAssets, reserveUnderlyingAssets } from '@/config/assets';
import { AsyncComputed } from '@/utils/AsyncComputed';
import { formatBigNumberString, numberWithCommas } from '@/utils/helpers';
import { AssetSymbol, Contracts } from '@/utils/types';
import { ethers } from 'ethers';
import { Component, Prop, Vue } from 'vue-property-decorator';
import type { ActionMethod } from 'vuex';
import { State, Getter, Action } from 'vuex-class';
import { ILeveragePool } from "@/contracts/ILeveragePool";
import ILeveragePoolABI from "@/contracts/ILeveragePool.json";
import { OverlyingToken } from "@/contracts/OverlyingToken";
import OverlyingTokenABI from "@/contracts/OverlyingToken.json";
import { tokenDecimals } from '@/utils/constants';
import AccountButton from "@/components/AccountButton.vue";
import { component } from 'vue/types/umd';

@Component({components: { AccountButton }})
export default class extends Vue {

  @State depositedBalances!: { [key: string]: string };
  @State contracts!: Contracts;
  @State provider!: ethers.providers.Provider;
  @State address!: string;

  @Getter isConnected!: boolean;

  get formattedSuppliedAmount() {
    if (!this.isConnected) {
      return '-';
    }

    let depositedBalance = 0;

    for (const asset of reserveUnderlyingAssets) {
      const balance = this.depositedBalances[asset];
      if (balance === undefined) {
        console.error("Missing asset!");
        continue;
      }

      depositedBalance += parseFloat(balance);
    }

    let [whole, decimal] = depositedBalance.toString().split('.')
    decimal = decimal ?? '00'
    if (decimal.length < 2) {
      decimal += '0';
    } else if (decimal.length > 2) {
      decimal = decimal[0] + decimal[1];
    }

    return numberWithCommas(parseInt(whole)) + '.' + decimal;
  }

  async getOverlyingToken(asset: AssetSymbol) {
    const leveragePoolAddress = this.contracts.LeveragePool;

    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      this.provider,
    ) as ILeveragePool;

    const tokenAddress = this.contracts[asset];
    const reserves = await leveragePool.getReserveForToken(tokenAddress);

    if (reserves.length > 1) {
      console.warn("More than 1 reserve for asset", asset);
    }

    const overlyingToken = new ethers.Contract(
      reserves[0],
      OverlyingTokenABI,
      this.provider,
    ) as OverlyingToken;

    return overlyingToken;
  }

  async getInterestEarned(asset: AssetSymbol) {
    const overlyingToken = await this.getOverlyingToken(asset);
    const abi = [ "event InternalUnderlyingTransfer(address indexed from, address indexed to, uint256 amount)" ];
    const iface = new ethers.utils.Interface(abi);

     // List all token transfers *to* address
    const toFilter = {
      address: overlyingToken.address,
      fromBlock: 0,
      topics: [
        // the name of the event, parentheses containing the data type of each event, no spaces
        ethers.utils.id("InternalUnderlyingTransfer(address,address,uint256)"),
        null,
        ethers.utils.hexZeroPad(this.address, 32)
      ]
    };

    const toEvents = await overlyingToken.queryFilter(toFilter);

    let provided = ethers.BigNumber.from(0);
    for (const log of toEvents) {
      const event = iface.parseLog(log);
      provided = provided.add(event.args.amount);
    }

    if (provided.eq(0)) {
      return "-";
    }

    // List all token transfers *from* address
    const fromFilter = {
      address: overlyingToken.address,
      fromBlock: 0,
      topics: [
        // the name of the event, parentheses containing the data type of each event, no spaces
        ethers.utils.id("InternalUnderlyingTransfer(address,address,uint256)"),
        ethers.utils.hexZeroPad(this.address, 32)
      ]
    };

    const fromEvents = await overlyingToken.queryFilter(fromFilter);

    let withdrawn = ethers.BigNumber.from(0);
    for (const log of fromEvents) {
      const event = iface.parseLog(log);
      withdrawn = withdrawn.add(event.args.amount);
    }

    const currentBalance = await overlyingToken.balanceOf(this.address);
    const earned = currentBalance.sub(provided.sub(withdrawn));

    return formatBigNumberString(ethers.utils.formatUnits(earned, tokenDecimals[asset]));
  }

  @AsyncComputed()
  async formattedInterestEarned() {
    if (!this.isConnected) {
      return '-';
    }

    let earnedInterest = 0;
    for (const asset of reserveUnderlyingAssets) {
      const interestEarned = await this.getInterestEarned(asset);
      if (interestEarned && interestEarned != '-') {
        earnedInterest += parseFloat(interestEarned);
      }
    }

    let [whole, decimal] = earnedInterest.toString().split('.')
    decimal = decimal ?? '00'
    if (decimal.length < 2) {
      decimal += '0';
    } else if (decimal.length > 2) {
      decimal = decimal[0] + decimal[1];
    }

    return numberWithCommas(parseInt(whole)) + '.' + decimal;
  }

}
